import React, { memo } from "react";
import Draggable from 'react-draggable';

import { ThemerExporter } from "./ThemerExporter";
import { PropColor } from "./PropColor";
import { PropSlider } from "./PropSlider";
import { ITheme } from "../../module";

import { BuildTheme, IThemerVars, ThemerProps, TThemerVars, varCOLOR, varSLIDER } from "./ThemerProps";
import styles from "./Themer.module.scss";

interface ThemerState {
    exporter: boolean
    openProp: string | null,
    themeProps: IThemerVars,
}

interface ThemerProps {
    onChange: (theme: ITheme) => void
}

class _Themer extends React.Component<ThemerProps, ThemerState> {

    state: ThemerState = {
        exporter: false,
        openProp: null,
        themeProps: ThemerProps
    };

    componentDidMount(): void {

    }

    onPickerOpen = (openProp: string | null) => this.setState({ openProp });

    onFontSizeChange = (e: React.FormEvent<HTMLInputElement>) => {
        //this.setState({ fontSize: parseFloat(e.currentTarget.value) });
    };

    onThemeChange = (tVar: IThemerVars) => {
        this.setState(state => ({ themeProps: { ...state.themeProps, ...tVar } }), this.onStateUpdate)
    };

    onStateUpdate = () => {
        const { themeProps } = this.state;

        let theme = BuildTheme({ theme: themeProps as TThemerVars }) as ITheme;

        this.props.onChange(theme);
    };

    exportTheme = () => {
        this.setState(state => ({ exporter: !state.exporter }));
    };

    render() {
        const { exporter, openProp, themeProps } = this.state;

        if (exporter)
            return <ThemerExporter themeProps={themeProps} onClose={this.exportTheme} />;

        return (
            <Draggable bounds="parent" handle="strong">
                <div className={styles.Themer}>
                    <strong className="cursor">
                        <div className={styles.header}>
                            <div>THEME <strong>EDITOR</strong></div>
                            <button className={styles.exportBtn} onClick={this.exportTheme}>Export</button>
                        </div>
                    </strong>
                    <div className={styles.body}>
                        {
                            Object.keys(themeProps).map(key => {
                                const tVar = themeProps[key];

                                if (tVar.type === varCOLOR)
                                    return <PropColor
                                        key={key}
                                        title={tVar.title}
                                        optional={!!tVar.optional}
                                        name={key}
                                        color={tVar.value}
                                        openProp={openProp}
                                        onChange={this.onThemeChange}
                                        onOpen={this.onPickerOpen} />;

                                if (tVar.type === varSLIDER)
                                    return <PropSlider
                                        key={key}
                                        title={tVar.title}
                                        optional={!!tVar.optional}
                                        step={tVar.step}
                                        max={tVar.max}
                                        units={tVar.units}
                                        name={key}
                                        value={tVar.value}
                                        onChange={this.onThemeChange} />;
                            })
                        }
                    </div>
                </div>
            </Draggable>
        )
    }
}


const Themer = memo(_Themer);
export { Themer };