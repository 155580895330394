import React from "react";
import {defaultTheme, ThemeBuilder} from "../../module";
import {IColor, IColors, setColor} from "../../module/@Theme/Colors";
import {IVariables} from "../../module/@Theme/Variables";
import styles from "./Themer.module.scss";

const {colors, variables} = defaultTheme;

export const varCOLOR = "[var] color";
export const varVALUE = "[var] value";
export const varSLIDER = "[var] slider";

type TCOLOR = typeof varCOLOR;
type TVALUE = typeof varVALUE;
type TSLIDER = typeof varSLIDER;

export type TThemerVarValue = string | null;

interface IThemerVar {
    title: string,
    value: TThemerVarValue,
    type: TCOLOR | TVALUE | TSLIDER,
    step?: number
    max?: number
    units?: string
    optional?: boolean
}

export interface IThemerVars {
    [x: string]: IThemerVar
}

export type TThemerVars = {
    borderRadius: IThemerVar
    fontSize: IThemerVar
    backgroundColor: IThemerVar
    color: IThemerVar
    primaryBg: IThemerVar
    primaryColor: IThemerVar
}

export const ThemerProps: TThemerVars = {
    borderRadius: {title: "Border Radius", value: variables.borderRadius, type: varSLIDER, step: .1, units: "rem", max: 6},
    fontSize: {title: "Font Size", value: variables.fontSize, type: varSLIDER, units: "px", max: 40},
    backgroundColor: {title: "Main Bg", value: colors.main.backgroundColor, type: varCOLOR},
    color: {title: "Main Color", value: null, type: varCOLOR, optional: true},
    primaryBg: {title: "Primary Bg", value: colors.primary.backgroundColor, type: varCOLOR},
    primaryColor: {title: "Primary Color", value: null, type: varCOLOR, optional: true},
};

interface BuildThemeProps {
    theme: TThemerVars,
    export?: boolean
}

export const BuildTheme = (props: BuildThemeProps) => {

    const {theme} = props;

    let variables: Partial<IVariables> = {};
    if (theme.borderRadius.value)
        variables.borderRadius = theme.borderRadius.value;

    if (theme.fontSize.value)
        variables.fontSize = theme.fontSize.value;

    const getColor = (v: IThemerVar) => v.value ? v.value : (v.optional ? undefined : "#fff");

    const colors = {
        main: setColor(getColor(theme.backgroundColor) as string, getColor(theme.color)),
        primary: setColor(getColor(theme.primaryBg) as string, getColor(theme.primaryColor)),
    };

    if (props.export)
        return <div className={styles.ExportedString}>
            <p><span className={styles.violet}>const</span> <span className={styles.orange}>theme</span> = <span className={styles.violet}>new</span> <span className={styles.yellow}>ThemeBuilder</span>()</p>
            <p className={styles.tab}>.<span className={styles.blue}>colors</span>({'{'}</p>
            {(Object.keys(colors) as Array<keyof typeof colors>).map(k => {
                const c = colors[k] as (typeof colors[keyof typeof colors]);

                return <p key={k} className={styles.tab2}><span className={styles.red}>{k}</span>: <span className={styles.blue}>setColor</span>(<Gp/>{c.backgroundColor}<Gp/>,<Gp/>{c.color}<Gp/>),</p>
            })}
            <p className={styles.tab}>{'}'})</p>
            <p className={styles.tab}>.<span className={styles.blue}>variables</span>({'{'}</p>
            {(Object.keys(variables) as Array<keyof typeof variables>).map(k => {
            const c = variables[k] as (typeof variables[keyof typeof variables]);

                return <p key={k} className={styles.tab2}><span className={styles.red}>{k}</span>: <Gp/>{c}<Gp/>,</p>
        })}
            <p className={styles.tab}>{'}'})</p>
            <p className={styles.tab}>.<span className={styles.blue}>build</span>();</p>
        </div>;

    return new ThemeBuilder()
        .colors(
            colors
        )
        .variables(
            variables
        )
        .build();
};

const Gp = () => <span className={styles.green}>"</span>;