import React, {lazy, Suspense} from "react";
import {BrowserRouter, Route} from "react-router-dom";

import Example from "./Example";
import App from "./Example";

export const Router = () => {
  
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Route path="/" exact component={Example}/>
        <Route path="/app" component={App}/>
      </Suspense>
    </BrowserRouter>
  )
};

export default Router;
