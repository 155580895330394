import chroma from "chroma-js";
import {getDarkColor, getLightColor, getMediumColor, getSecondaryColor, IColors} from "../Colors";
import {ITheme} from "./ThemeBuilder";


export function ColorsBuilder(colors: Partial<IColors>, defTheme: ITheme) {

    let c = {...colors};

    function themeColors(colors: Partial<IColors>): IColors {
        return {...defTheme.colors, ...colors};
    }

    if (c.main && !c.accent)
        c.accent = {
            color: chroma(c.main.color).darken().hex(),
            backgroundColor: chroma(c.main.backgroundColor).darken().hex()
        };

    if (c.main && !c.secondary)
        c.secondary = getSecondaryColor(c.main);

    if (c.main && !c.light)
        c.light = getLightColor(c.main);

    if (c.main && !c.medium)
        c.medium = getMediumColor(c.main);

    if (c.main && !c.dark)
        c.dark = getDarkColor(c.main);

    return themeColors(c);
}



