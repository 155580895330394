import React, { memo, createRef } from "react";
import { css, StyleSheet } from "aphrodite";
import { defaultTheme, Field } from "..";
import { withForm, WithFormProps } from "../Form/@hoc/withForm";
import { IInputOnChange } from "./@hoc/withInput";
import { IInputTheme } from "./Input.types";
import { withInputTheme, WithInputThemeProps } from "./Input.theme";
import { luminance } from "../@Theme/Colors";

type State = {
  isValid?: boolean
  edited: boolean
  value: string
}

interface InputProps extends WithFormProps {
  value: string
  onChange: (value: IInputOnChange) => void
  name: string
  allowEmpty?: boolean
  validator?: any
  readOnly?: false
  type?: string
  theme?: IInputTheme
}

interface InputDefaultProps extends InputProps, WithInputThemeProps {
  onInternalChange?: (e: string) => void
  inputRef: React.Ref<HTMLInputElement>
}

class _InputDefault extends React.Component<InputDefaultProps, Partial<State>> {
  
  state: State = {
    isValid: true,
    edited: false,
    value: this.props.value
  };
  
  componentDidUpdate(prevProps: Readonly<InputDefaultProps>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.value !== this.props.value || prevProps.hash !== this.props.hash) {
      const isValid = this.isValid(this.props.value);
      this.setState({value: this.props.value, edited: false, isValid});
    } else {
      this.propagateOnChange(prevProps, prevState);
    }
  }
  
  propagateOnChange = (prevProps: Readonly<InputDefaultProps>, prevState: Readonly<State>) => {
    if (this.changesExist() || this.state.edited !== prevState.edited)
      this.props.onChange({data: {[this.props.name]: this.getValue()}, error: false});
  };
  
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const isValid = this.isValid(value);
    
    this.setState(state => state.value !== value && ({value, isValid, edited: this.changesExist(value)} as Partial<State>));
  };
  
  isValid = (value: string) => {
    const { validator, allowEmpty } = this.props;
    
    return !!(validator ? validator(value) : value > "" || allowEmpty);
  };
  
  changesExist = (value?: string) => this.props.value !== this.getValue(value);
  
  getValue = (value?: string) => value || this.state.value;
  
  render() {
    const {value, edited, isValid} = this.state;
    const {props} = this;
  
    const colors = props.colors || defaultTheme.colors;
    const theme = props.theme || defaultTheme.input;
    
    const styles = StyleSheet.create({
      input: {
        ...theme.default,
        backgroundColor: theme.colors.backgroundColor,
        borderColor:  isValid ? (edited ? colors.primary.backgroundColor : theme.colors.borderColor) : theme.colors.errorColor,
        
        ":hover": {
          ...theme.hover,
          borderColor:  isValid ? (edited ? colors.primary.backgroundColor :  luminance(theme.colors.borderColor, .5, .3, .3)) : theme.colors.errorColor,
        },
        
        ":focus": {
          ...theme.focus,
          borderColor: isValid ? edited ? colors.primary.backgroundColor : luminance(theme.colors.borderColor, .5, .3, .3) : theme.colors.errorColor,
        },
      },
    });
    
    return (
      <input ref={props.inputRef}
             value={value}
             className={css(styles.input)}
             name={name}
             onChange={this.onChange}
             type={props.type}/>
    )
  }
}

const InputWithTheme = withInputTheme(_InputDefault);
const InputWithForm = withForm(InputWithTheme);

const Input = memo((props: InputProps) => {
  const ref = createRef<HTMLInputElement>();
  
  return (
    <InputWithForm inputRef={ref} {...props} />
  );
});

const FieldInput = memo((props: InputProps) => {
  return (
    <Field>
      <Input {...props} />
    </Field>
  );
});


export { FieldInput };