import React from "react";

import {BuildTheme, IThemerVars, TThemerVars} from "./ThemerProps";
import styles from "./Themer.module.scss";

interface ExporterState {
    contentString: any
}

interface ExporterProps {
    themeProps: IThemerVars
    onClose: () => void
}

export class ThemerExporter extends React.Component<ExporterProps, ExporterState> {

    state: ExporterState = {
        contentString: null
    };

    componentDidMount(): void {
        const contentString = BuildTheme({theme: this.props.themeProps as TThemerVars, export: true});

        this.setState({contentString});
    }

    _onClose = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        this.props.onClose();
    };


    render() {
        return (
            <div className={styles.exporter} onClick={this._onClose}>
                <div>
                    <div className={styles.header}><button onClick={this._onClose}>x</button></div>
                    <div className={styles.body}>{this.state.contentString}</div>
                </div>
            </div>
        )
    }
}

