import React, {memo} from "react";
import {css, StyleSheet} from "aphrodite";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {defaultTheme, ITheme, withTheme} from "..";

type IconProps = { icon: IconProp, primary?: boolean, color?: string, theme?: ITheme } & Partial<DefaultProps>
type DefaultProps = Readonly<typeof defaultProps>

const defaultProps = {
    primary: false
};

const _Icon = memo((props: IconProps): JSX.Element => {
    const theme = props.theme || defaultTheme;

    const styles = StyleSheet.create({
        icon: {
            fontSize: "inherit",
            color: props.primary
                ? theme.colors.primary.backgroundColor
                : props.color
                    ? props.color
                    : "inherit"
        }
    });


    return <FontAwesomeIcon icon={props.icon} className={css(styles.icon)}/>
});

const Icon = withTheme(_Icon);

export {Icon};
export {library as iconLibrary} from "@fortawesome/fontawesome-svg-core";