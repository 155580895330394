import chroma from "chroma-js";
import * as types from "./Input.types";
import { luminance, IColors } from "../@Theme/Colors";
import { IVariables } from "../@Theme/Variables";
import React from "react";
import { ThemeContext } from "..";
import { IInputTheme } from "./Input.types";

export function InputThemeBuilder(variables: IVariables, colors: IColors): types.IInputTheme {

    const shared = {
        display: "flex",
        flex: "auto",
        fontSize: "1em",
        borderRadius: `${variables.borderRadius}rem`,
        // transition: "all 500ms",
    };

    const focusBackground = chroma(colors.main.backgroundColor).brighten(.5).hex();
    const focusStyles = {
        outline: "none",
        boxShadow: "none",
        // backgroundColor: focusBackground,
    };

    return {
        colors: {
            backgroundColor: chroma(colors.main.backgroundColor).darken(.1).hex(),
            borderColor: chroma(colors.main.backgroundColor).darken(.1).hex(),
            color: "inherit",
            errorColor: colors.error,
            successColor: colors.success
        },
        default: {
            ...shared,
            ...colors.main,
            outline: "none",
            padding: ".75rem",
            border: "2px solid transparent",
        },
        hover: {
            // backgroundColor: luminance(colors.main.backgroundColor, .5, .2, .2),
        },
        focus: {
            ...focusStyles,

        },
    };
}

export interface WithInputThemeProps { theme?: IInputTheme, colors?: IColors }
export const withInputTheme = <P extends object>(Component: React.ComponentType<P>): React.FC<P & WithInputThemeProps> => (props: WithInputThemeProps) => (
    <ThemeContext.Consumer>
        {theme => <Component {...props as P} theme={theme.input} colors={theme.colors} />}
    </ThemeContext.Consumer>
);
