import React, {memo} from "react";

import styles from "./ShowCase.module.scss";

interface CheckProps {
    title: any
    checked: boolean
    onClick: () => void
}

export const Check = memo((props: CheckProps) => {

    const onChange = () => {};

    return (
        <div className={styles.Check} onClick={props.onClick}>
            {props.checked !== null
            ? <input type="checkbox" checked={props.checked} onChange={onChange} />
            : <div style={{width: 20, height: 20, marginRight: ".5rem"}}/>}
            <label>
                <span/>
                {props.title}
            </label>
            {/*{props.checked*/}
            {/*    ? "X"*/}
            {/*    : "-"*/}
            {/*}*/}
        </div>

    )
});