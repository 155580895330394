import React, { memo, ReactNode } from "react";
import classNames from "classnames";
import { StyleSheet, css } from 'aphrodite';
import { defaultTheme, ITheme, withTheme } from "..";

type ContainerProps = { children?: ReactNode | ReactNode[], asRow?: boolean, width?: string | number, height?: string | number, theme?: ITheme }
const _Container = memo((props: ContainerProps): JSX.Element => {
  
  const theme = props.theme || defaultTheme;
  
  const styles = StyleSheet.create({
    container: {
      position: "relative",
      overflowX: "hidden",
      overflowY: "auto",
      fontSize: `${theme.variables.fontSize}px`,
      width: props.width ? props.width : "100%",
      height: props.height ? props.height : "auto",
      minHeight: "100%",
      display: "flex",
      flex: "auto",
      flexDirection: props.asRow ? "row" : "column",
      // flexWrap: "wrap",
      padding: 0,
      color: theme.colors.main.color,
      backgroundColor: theme.colors.main.backgroundColor,
    }
  });
  
  return (
    <div className={css(styles.container)}>
      {props.children}
    </div>
  )
});

type HeaderProps = { children?: ReactNode | ReactNode[], theme?: ITheme }
const _Header = memo((props: HeaderProps): JSX.Element => {
  
  const theme = props.theme || defaultTheme;
  
  const styles = StyleSheet.create({
    header: {
      position: "sticky",
      top: "0",
      display: "flex",
      flexDirection: "column",
      flex: "none",
      width: "100%",
      overflow: "hidden",
      alignItems: "center",
      justifyContent: "flex-start",
      color: theme.colors.main.color,
      background: theme.colors.light.backgroundColor,
    }
  });
  
  return (
    <header className={css(styles.header)} style={{zIndex: 10}}>
      {props.children}
    </header>
  )
});

type SectionProps = { children?: ReactNode | ReactNode[], asRow?: boolean, width?: string | number, height?: string | number, theme?: ITheme }
const _Section = memo((props: SectionProps): JSX.Element => {
  
  const theme = props.theme || defaultTheme;
  
  const styles = StyleSheet.create({
    section: {
      display: "flex",
      flex: "none",
      height: "auto",
      flexDirection: props.asRow ? "row" : "column",
      flexWrap: "wrap",
      padding: ".5rem 1rem",
    }
  });
  
  return (
    <section className={css(styles.section)}>
      {props.children}
    </section>
  )
});

type RowProps = { children?: ReactNode | ReactNode[], className?: string, contentCentered?: boolean, contentMiddle?: boolean, theme?: ITheme };
const _Row = memo((props: RowProps): JSX.Element => {
  
  const styles = StyleSheet.create({
    row: {
      width: "100%",
      display: "flex",
      alignItems: props.contentMiddle ? "center" : "flex-start",
      justifyContent: props.contentCentered ? "center" : "flex-start",
      flexWrap: "wrap",
      flex: "auto",
      padding: ".5rem 1rem",
    }
  });
  
  return (
    <div className={
      classNames({
        [`${css(styles.row)}`]: true,
        [`${props.className}`]: props.className,
      })
    }>
      {props.children}
    </div>
  )
});

type ColumnProps = {
  flexNone?: boolean
  children?: ReactNode | ReactNode[]
  contentCentered?: boolean
  contentMiddle?: boolean
  theme?: ITheme
};
const _Column = memo((props: ColumnProps): JSX.Element => {
  
  const styles = StyleSheet.create({
    column: {
      minWidth: "300px",
      display: "flex",
      flexDirection: "column",
      alignItems: props.contentCentered ? "center" : "flex-start",
      justifyContent: props.contentMiddle ? "center" : "flex-start",
      padding: ".5rem 1rem",
    },
    flexAuto: {
      flex: 1
    },
    flexNone: {
      flex: "none"
    }
  });
  
  return (
    <div className={css(styles.column, props.flexNone ? styles.flexNone : styles.flexAuto)}>
      {props.children}
    </div>
  )
});

const Container = withTheme(_Container);
const Header = withTheme(_Header);
const Section = withTheme(_Section);
const Row = withTheme(_Row);
const Column = withTheme(_Column);

export { Container, Header, Section, Row, Column };

