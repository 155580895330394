import React, {ReactNode} from "react";
import {defaultTheme, ITheme} from "../..";

export const ThemeContext = React.createContext(defaultTheme);

type Props = {
  theme: ITheme,
  children: ReactNode | ReactNode[]
};

export const ThemeProvider = (props: Props) => (
  <ThemeContext.Provider value={props.theme}>
    {props.children}
  </ThemeContext.Provider>
);

export default ThemeProvider;