import React, {memo} from "react";
import chroma from "chroma-js";
import {css, StyleSheet} from "aphrodite";
import {defaultTheme, ITheme, withTheme} from "..";

const Loader = memo((props: {primary?: boolean, theme?: ITheme}) => {

    const theme = props.theme || defaultTheme;

    const loadingFrames = {
        '0%': {
            transform: 'scale(1)',
        },

        '20%': {
            transform: 'scale(1, 2.2)',
        },

        '40%': {
            transform: 'scale(1)',
        },
    };

    const styles = StyleSheet.create({
        loader: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "30px",
            minWidth: "26px",
        },
        bar: {
            backgroundColor: props.primary ? chroma(theme.colors.primary.backgroundColor).alpha(.5).hex() : theme.loader.color,
            width: theme.loader.width,
            display: "inline-flex",
            height: "14px",
            borderRadius: "4px",
            margin: "0 1px",
            animationName: [loadingFrames],
            animationDuration: '1s',
            animationIterationCount: 'infinite',

            ':nth-child(1)': {
                animationDelay: 0,
            },
            ':nth-child(2)': {
                animationDelay: "0.09s"
            },
            ":nth-child(3)": {
                animationDelay: ".18s"
            },
            ":nth-child(4)": {
                animationDelay: ".27s"
            }
        }
    });

    return (
        <div className={css(styles.loader)}>
            <div className={css(styles.bar)}/>
            <div className={css(styles.bar)}/>
            <div className={css(styles.bar)}/>
            <div className={css(styles.bar)}/>
        </div>
    )
});

export default withTheme(Loader);