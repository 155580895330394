import React, { memo, ReactNode } from "react";
import { StyleSheet, css } from 'aphrodite';
import { defaultTheme, ITheme, withTheme } from "..";

type FieldProps = { children?: ReactNode | ReactNode[], theme?: ITheme }
const _Field = memo((props: FieldProps): JSX.Element => {
  
  const theme = props.theme || defaultTheme;
  
  const styles = StyleSheet.create({
    field: {
      fontSize: `${theme.variables.fontSize}px`,
      width: "100%",
      display: "flex",
      flex: "none",
      flexWrap: "wrap",
      padding: 0,
      color: theme.colors.main.color,
      backgroundColor: theme.colors.main.backgroundColor,
    }
  });
  
  return (
    <div className={css(styles.field)}>
      {props.children}
    </div>
  )
});

const Field = withTheme(_Field);

export { Field };

