import React, {memo} from "react";
import {ChromePicker} from 'react-color';

import {Check} from "./Check";
import {IThemerVars, TThemerVarValue, varCOLOR} from "./ThemerProps";
import styles from "./Themer.module.scss";

function RGBAToHexA(props: any) {
    let r = props.r.toString(16);
    let g = props.g.toString(16);
    let b = props.b.toString(16);
    let a = Math.round(props.a * 255).toString(16);

    if (r.length == 1)
        r = "0" + r;
    if (g.length == 1)
        g = "0" + g;
    if (b.length == 1)
        b = "0" + b;
    if (a.length == 1)
        a = "0" + a;

    return "#" + r + g + b + a;
}

interface ColorPickerProps {
    title: string
    optional: boolean
    name: string
    color: TThemerVarValue
    openProp: string | null
    onChange: (c: IThemerVars) => void
    onOpen: (c: string | null) => void
}

export const PropColor = memo((props: ColorPickerProps) => {

    const isOpen = props.openProp === props.name;

    const _onChange = (color: any) => {
        const {onChange, ...rest} = props;
        props.onChange({[props.name]: {...rest, value: RGBAToHexA(color.rgb), type: varCOLOR}});
    };

    const _toggleColor = () => {
        props.onChange({[props.name]: {title: props.title, value: props.color ? null : "#fff", type: varCOLOR, optional: props.optional}});
    };

    const toggleOpenState = () => {
        if (props.color)
            if (isOpen) {
                props.onOpen(null);
            } else {
                props.onOpen(props.name);
            }
    };

    return (
        <div className={styles.colorPickerPreview}>
            <div className={styles.head}>
                <Check title={props.title} optional={props.optional} checked={!!props.color} onClick={_toggleColor}/>
                <div className={styles.colorPreview}>
                    <div onClick={toggleOpenState} style={{backgroundColor: props.color || "rgba(0,0,0,.1)"}}/>
                </div>
            </div>

            {isOpen && props.color &&
            <ChromePicker color={props.color} onChangeComplete={_onChange}/>}

        </div>
    )
});