import React, {memo} from "react";
import Slider, {Handle} from "rc-slider";
import 'rc-slider/assets/index.css';

import {IThemerVars, TThemerVarValue} from "./ThemerProps";
import {varSLIDER} from "./ThemerProps";
import {Check} from "./Check";
import styles from "./Themer.module.scss";

interface PropSliderProps {
    title: string
    step: number | undefined
    max: number | undefined
    units: string | undefined
    optional: boolean
    name: string
    value: TThemerVarValue
    onChange: (c: IThemerVars) => void
}

export const PropSlider = memo((props: PropSliderProps) => {

    const _onChange = (value: string) => {
        const {onChange, ...rest} = props;
        props.onChange({[props.name]: {...rest, value: value, type: varSLIDER}});
    };

    const _toggleColor = () => {
    };

    const renderSlider = () => {

        const handle = (p: any) => {
            const {value, dragging, index, ...restProps} = p;

            if (dragging && value !== props.value) {
                _onChange(value);
            }

            return (
                <Handle value={value} {...restProps} />
            );
        };

        return (
            <div style={{display: "block"}}>
                <Slider defaultValue={props.value !== null ? parseFloat(props.value) : 0}
                        min={0}
                        max={props.max || 60}
                        step={props.step || 1}
                        handle={handle}/>
            </div>
        );
    };

    return (
        <div className={styles.colorPickerPreview}>
            <div className={styles.head}>
                <Check title={`${props.title} (${props.value}${props.units || ""})`}
                       optional={props.optional}
                       checked={!!props.value}
                       onClick={_toggleColor}/>
            </div>

            {renderSlider()}

        </div>
    )
});