import React, { memo } from "react";
import { StyleSheet, css, StyleDeclarationValue } from "aphrodite";

import { defaultTheme, Loader, Icon } from "..";
import { withButtonTheme } from "./Button.theme";
import * as types from "./Button.types";
import { IButtonTheme } from "./Button.types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type ButtonProps = {
  className?: StyleDeclarationValue,
  onClick?: (e: React.FormEvent<HTMLButtonElement>) => void
  icon?: IconProp
  title?: string
  disabled?: boolean
  type?: types.IButtonType
  theme?: IButtonTheme
  active?: boolean
  activeClass?: StyleDeclarationValue
  children?: React.ReactNode | React.ReactNode[]
}

const Button = memo((props: ButtonProps): JSX.Element => {
  
  const theme = props.theme || defaultTheme.button;
  
  const styles = StyleSheet.create({
    button: {
      display: "flex",
      alignItems: "center",
      border: "2px solid transparent",
      transition: "width 6s",
      ...theme && (props.type
        ? props.disabled ? theme[props.type].disabled : theme[props.type].default
        : props.disabled ? theme.default.disabled : theme.default.default),
      
      ":not(:last-child)": {
        marginRight: "3px"
      },
      
    },
    inActive: {
      ":hover": {
        ...theme && (props.type
          ? props.disabled ? theme[props.type].disabled : theme[props.type].hover
          : props.disabled ? theme.default.disabled : theme.default.hover)
      },
      ":active": {
        ...theme && (props.type
          ? props.disabled ? theme[props.type].disabled : theme[props.type].active
          : props.disabled ? theme.default.disabled : theme.default.active)
      },
      ":focus": {
        ...theme && (props.type
          ? props.disabled ? theme[props.type].disabled : theme[props.type].focus
          : props.disabled ? theme.default.disabled : theme.default.focus)
      }
    },
    active: {

    },
    loaderWrapper: {
      lineHeight: "inherit",
    },
    marginLeft: {
      marginLeft: ".4rem"
    }
  });
  
  const customClass = props.active ? props.activeClass : props.className;
  
  return (
    <button
      className={css(styles.button, props.className, props.active ? styles.active : styles.inActive, customClass && customClass)}
      onClick={props.onClick}>
      {props.icon && <div className={css(styles.loaderWrapper)}><Icon icon={props.icon}/></div>}
      {props.children}
      {props.title && <span className={css(props.icon && styles.marginLeft)}>{props.title}</span>}
    </button>
  )
});

type State = { isLoading: boolean }
type LoadingButtonProps = ButtonProps & { isLoading: boolean, primaryLoader?: boolean, theme?: IButtonTheme }

class _LoadingButton extends React.Component<LoadingButtonProps, State> {
  
  static readonly defaultProps = {isLoading: false} as LoadingButtonProps;
  readonly state = {isLoading: false} as State;
  
  styles = StyleSheet.create({
    loaderWrapper: {
      marginRight: ".5rem"
    }
  });
  
  noClick = () => {
  };
  
  render() {
    const {isLoading, onClick, ...props} = this.props;
    
    return (
      <Button {...props} onClick={isLoading ? this.noClick : onClick}>
        {isLoading && <div className={css(this.styles.loaderWrapper)}><Loader primary={props.primaryLoader}/></div>}
      </Button>
    );
  }
}

const LoadingButton = withButtonTheme(_LoadingButton);

export { LoadingButton };
export default withButtonTheme(Button);


