import React, { memo, ReactNode } from "react";
import chroma from "chroma-js";
import { StyleSheet, css } from 'aphrodite';
import { Button, defaultTheme, ITheme, ThemeProvider, withTheme } from "..";
import FormProvider from "./FormProvider/FormProvider";

type FormProps = { hash: string, children?: ReactNode | ReactNode[], theme?: ITheme }
const _Form = memo((props: FormProps): JSX.Element => {
  
  const theme = props.theme || defaultTheme;
  
  const styles = StyleSheet.create({
    form: {
      border: `1px dotted ${chroma(theme.colors.main.color).alpha(.1).hex()}`,
      fontSize: `${theme.variables.fontSize}px`,
      width: "100%",
      display: "flex",
      flex: "none",
      flexWrap: "wrap",
      padding: "1rem",
    }
  });
  
  return (
    <FormProvider hash={props.hash}>
      <div className={css(styles.form)}>
        {props.children}
      </div>
    </FormProvider>
  )
});
const Form = withTheme(_Form);

type FormButtonsProps = {
  onSubmit: () => void
  onCancel: () => void
  hasChanges: boolean
  floating?: boolean | null
  theme?: ITheme
}
const _FormButtons = memo((props: FormButtonsProps): JSX.Element => {
  
  const theme = props.theme || defaultTheme;
  
  const styles = StyleSheet.create({
    buttons: {
      display: "flex",
      marginLeft: "auto",
      padding: ".5rem 0",
    },
    floating: {
      position: "fixed",
      right: "1rem",
      bottom: "1rem"
    }
  });
  
  const onCancel = () => props.hasChanges && props.onCancel();
  const onSubmit = () => props.hasChanges && props.onSubmit();
  
  return (
    <div className={css(styles.buttons, props.floating && styles.floating)}>
      <Button onClick={onCancel} icon={["fas", "times"]} title="Cancel" disabled={!props.hasChanges}/>
      <Button onClick={onSubmit} icon={["fas", "check"]} title="Submit" type={"primary"} disabled={!props.hasChanges}/>
    </div>
  )
});
const FormButtons = withTheme(_FormButtons);

export { Form, FormButtons };

