import React, { memo } from "react";
import {Check} from "./Check";

import { IShowCaseVar } from "./ShowCase";
import styles from "./ShowCase.module.scss";


interface ColorPickerProps {
  value: any,
  onChange: (option: IShowCaseVar) => void
}

export const PropValue = memo((props: ColorPickerProps) => {
  
  const _toggleBoolean = () => {
    props.onChange({[props.value.key]: {...props.value, value: !props.value.value}});
  };
  
  // const _onChange = (color: any) => {
  //   const {onChange, ...rest} = props;
  //   props.onChange({[props.name]: {...rest, value: RGBAToHexA(color.rgb), type: varCOLOR}});
  // };
  
  
  return (
    <div className={styles.Item}>
      <Check title={props.value.name} checked={typeof props.value.value === "boolean" ? props.value.value : null} onClick={_toggleBoolean}/>
    </div>
  )
});