import chroma from "chroma-js";

export type TColor = string;

export interface IColor {
    color: TColor,
    backgroundColor: TColor
}

export type IColors = {
    main: IColor,
    primary: IColor, //-----------------
    secondary: IColor,
    accent: IColor,
    light: IColor,
    medium: IColor,
    dark: IColor,
    error: string,
    success: string,
    info: string
}

export const getSecondaryColor = (color: IColor) => setColor(chroma(color.backgroundColor).darken().hex());
export const getLightColor = (color: IColor) => setColor(chroma(color.backgroundColor).darken(1).hex());
export const getMediumColor = (color: IColor) => setColor(chroma(color.backgroundColor).darken(2.5).hex());
export const getDarkColor = (color: IColor) => setColor(chroma(color.backgroundColor).darken(5).hex());

const main = setColor("#eee");
const primary = setColor("#4b82f3");

export const colors: IColors = {
    main,
    primary,
    secondary: getSecondaryColor(main),
    accent: setColor("#ff9013"),
    light: getLightColor(main),
    medium: getMediumColor(main),
    dark: getDarkColor(main),
    error: "#E02C2C",
    success: "#0f0",
    info: "#00f",
};

export function alpha(color: string, value: number = .5) {
    return chroma(color).alpha(value).hex()
}

export function luminance(color: string, value: number = .35, darken: number = .5, brighten: number = .5) {
    return chroma(color).luminance() <= value
        ? chroma(color).brighten(brighten).hex()
        : chroma(color).darken(darken).hex()
}

export function reverseLuminance(color: string, value: number = .35, darken: number = .5, brighten: number = .5) {
    return chroma(color).luminance() >= value
        ? chroma(color).brighten(brighten).hex()
        : chroma(color).darken(darken).hex()
}

export function luminanceWithSaturate(color: string, value: number = .35, darken: number = 4, brighten: number = 3) {
    return chroma(color).luminance() <= value
        ? chroma(color).brighten(brighten).desaturate().hex()
        : chroma(color).darken(darken).hex()
}

export function setColor(backgroundColor: string, color?: string) {
    return {
        backgroundColor,
        color: color || luminanceWithSaturate(backgroundColor)
    }
}