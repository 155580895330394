import { colors, IColors } from "../Colors";
import { variables, IVariables } from "../Variables";
import { ILoaderTheme } from "../../Loader/Loader.types";
import { IButtonTheme } from "../../Button/Button.types";
import { IInputTheme } from "../../Input/Input.types";
import { IEditorTheme } from "../../EditorRTF/Editor.types";
import { ColorsBuilder } from "./ColorsBuilder";
import { ButtonThemeBuilder } from "../../Button/Button.theme";
import { LoaderThemeBuilder } from "../../Loader/Loader.theme";
import { InputThemeBuilder } from "../../Input/Input.theme";
import { EditorThemeBuilder } from "../../EditorRTF/Editor.theme";

export interface ITheme {
  colors: IColors,
  variables: IVariables
  loader: ILoaderTheme
  button: IButtonTheme
  input: IInputTheme
  editor: IEditorTheme
}

export const defaultTheme: ITheme = {
  colors: colors,
  variables: variables,
  loader: LoaderThemeBuilder(variables, colors),
  button: ButtonThemeBuilder(variables, colors),
  input: InputThemeBuilder(variables, colors),
  editor: EditorThemeBuilder(variables, colors),
};

export class ThemeBuilder {
  private theme = {...defaultTheme} as ITheme;
  
  colors(colors: Partial<IColors>): ThemeBuilder {
    this.theme.colors = ColorsBuilder(colors, defaultTheme);
    return this;
  }
  
  variables(variables: Partial<IVariables>): ThemeBuilder {
    this.theme.variables = themeVariables(variables);
    return this;
  }
  
  build(): ITheme {
    this.theme.loader = LoaderThemeBuilder(this.theme.variables, this.theme.colors);
    this.theme.button = ButtonThemeBuilder(this.theme.variables, this.theme.colors);
    this.theme.input = InputThemeBuilder(this.theme.variables, this.theme.colors);
    this.theme.editor = EditorThemeBuilder(this.theme.variables, this.theme.colors);
    return this.theme;
  }
}

export function themeVariables(variables: Partial<IVariables>): IVariables {
  return {...defaultTheme.variables, ...variables};
}

