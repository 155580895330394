import React from "react";
import chroma from "chroma-js";
import { ThemeContext } from "..";
import * as types from "./Editor.types";
import { luminance, IColors } from "../@Theme/Colors";
import { IVariables } from "../@Theme/Variables";
import { IEditorTheme } from "./Editor.types";

export function EditorThemeBuilder(variables: IVariables, colors: IColors): types.IEditorTheme {
  
  const shared = {
    display: "flex",
    flex: "1",
    fontSize: "1em",
    borderRadius: `${variables.borderRadius}rem`,
    // transition: "background 300ms, border 300ms",
    border: "2px solid transparent",
    pointerEvents: "auto",
    padding: ".75rem",
  };
  
  const focusBackground = chroma(colors.main.backgroundColor).brighten(.5).hex();
  const focusStyles = {
    outline: "none",
    boxShadow: "none",
    backgroundColor: chroma(colors.main.backgroundColor).darken(.1).hex(),
  };
  
  return {
    colors: {
      backgroundColor: chroma(colors.main.backgroundColor).darken(.1).hex(),
      borderColor: chroma(colors.main.backgroundColor).darken(.1).hex(),
      color: "inherit",
      errorColor: colors.error,
      successColor: colors.success
    },
    toolbar: {
      backgroundColor: colors.main.backgroundColor
    },
    toolbarButtons: {
      default: {
        backgroundColor: colors.main.backgroundColor
      },
      hover: {
        backgroundColor: colors.main.backgroundColor
      },
      active: {
        color: colors.primary.color,
        backgroundColor: colors.primary.backgroundColor
      }
    },
    default: {
      ...shared,
      ...colors.main,
      outline: "none",
      minHeight: "100px"
    },
    hover: {
      // backgroundColor: luminance(colors.main.backgroundColor, .5, .2, .2),
    },
    focus: {
      ...focusStyles,
    },
  };
}

export interface WithEditorThemeProps {
  theme?: IEditorTheme,
  colors?: IColors
}

export const withEditorTheme = <P extends object>(Component: React.ComponentType<P>): React.FC<P & WithEditorThemeProps> => (props: WithEditorThemeProps) => (
  <ThemeContext.Consumer>
    {theme => <Component {...props as P} theme={theme.editor} colors={theme.colors}/>}
  </ThemeContext.Consumer>
);
