import React, { memo } from "react";
import Draggable from 'react-draggable';

import { PropValue } from "./PropValue";
import styles from "./ShowCase.module.scss";

export enum EOptions {
  toolbar = "toolbar",
  formFloatingButtons = "formFloatingButtons"
}

export type TShowCaseVarValue = string | boolean | number;

export interface IShowCaseVar {
  [x: string]: IShowCaseVarValue
}

export interface IShowCaseVarValue {
  key: EOptions
  name: string
  value: TShowCaseVarValue
}

export interface IShowCaseOptions {
  toolbar: boolean
  formFloatingButtons: boolean
}

export const ShowCaseProps = {
  toolbar: {key: EOptions.toolbar, name: "RTF Toolbar", value: true},
  formFloatingButtons: {key: EOptions.formFloatingButtons, name: "Floating form buttons", value: false}
};

interface ShowCaseState {
  optionsProps: any,
}

interface ShowCaseProps {
  onChange: (option: IShowCaseOptions) => void
}

class _ShowCase extends React.Component<ShowCaseProps, ShowCaseState> {
  
  state = {
    optionsProps: ShowCaseProps
  };
  
  componentDidMount(): void {
  
  }
  
  onChange = (option: IShowCaseVar) => {
    console.log("ddd", option);
    this.setState(state => ({optionsProps: {...state.optionsProps, ...option}}), this.onStateUpdate)
  };
  
  onStateUpdate = () => {
    const { optionsProps } = this.state;
    
    const options = {
      toolbar: optionsProps.toolbar.value,
      formFloatingButtons: optionsProps.formFloatingButtons.value
    };

    this.props.onChange(options);
  };
  
  render() {
    const {optionsProps} = this.state;
    
    return (
      <Draggable bounds="parent" handle="strong">
        <div className={styles.ShowCase}>
          <strong className="cursor">
            <div className={styles.header}>
              <div>OPTIONS <strong>EDITOR</strong></div>
              {/*<button className={styles.exportBtn} onClick={this.exportTheme}>Export</button>*/}
            </div>
          </strong>
          <div className={styles.body}>
            {
              Object.values(optionsProps).map((value, key) =>
                <PropValue key={key} value={value} onChange={this.onChange}/>
              )
            }
          </div>
        </div>
      </Draggable>
    )
  }
}


const ShowCase = memo(_ShowCase);
export { ShowCase };