import chroma from "chroma-js";
import * as types from "./Button.types";
import {IColors, luminance, TColor} from "../@Theme/Colors";
import {IVariables} from "../@Theme/Variables";
import React from "react";
import {ThemeContext, WithThemeProps} from "..";

export function ButtonThemeBuilder(variables: IVariables, colors: IColors): types.IButtonTheme {

    const shared = {
        fontSize: "1em",
        padding: ".2rem .7rem",
        minHeight: "40px",
        lineHeight: "100%",
        borderRadius: `${variables.borderRadius}rem`
    };

    const buttonDefaultActive = {
        outline: "none",
        boxShadow: "0 1px 2px 0 rgba(26,115,232,0.451), 0 1px 3px 1px rgba(26,115,232,0.302);"
    };
    const buttonDefaultFocus = {
        outline: "none",
        boxShadow: "none"
    };
    const buttonDefaultDisabled = {
        ...shared,
        outline: "none",
        boxShadow: "none",
        color: chroma(colors.main.color).alpha(.5).hex(),
        backgroundColor: chroma(colors.main.backgroundColor).darken(.2).alpha(.5).hex()
    };

    const activeColor = (color: TColor) => luminance(color);
    const hoverColor = (color: TColor) => chroma(color).darken(.3).hex();
    const hoverShadow = (color: TColor) => `0 1px 2px 0 ${chroma(color).darken(.2).alpha(.451)},
                0 1px 3px 1px ${chroma(color).darken(.2).alpha(.302)}`;

    return {
        default: {
            default: {
                ...shared,
                ...colors.main,
                backgroundColor: chroma(colors.main.backgroundColor).darken(.2).hex(),
            },
            hover: {
                backgroundColor: hoverColor(colors.main.backgroundColor),
                boxShadow: hoverShadow(colors.main.backgroundColor),
            },
            active: {
                ...buttonDefaultActive,
                backgroundColor: activeColor(colors.main.backgroundColor),
            },
            disabled: {
                ...buttonDefaultDisabled,
            },
            focus: buttonDefaultFocus,
        },
        primary: {
            default: {
                ...shared,
                ...colors.primary
            },
            hover: {
                backgroundColor: hoverColor(colors.primary.backgroundColor),
                boxShadow: hoverShadow(colors.primary.backgroundColor),
            },
            active: {
                ...buttonDefaultActive,
                backgroundColor: activeColor(colors.primary.backgroundColor),
            },
            disabled: {
                ...buttonDefaultDisabled,
            },
            focus: buttonDefaultFocus,
        },
        secondary: {
            default: {
                ...shared,
                ...colors.secondary
            },
            hover: {
                backgroundColor: hoverColor(colors.secondary.backgroundColor),
                boxShadow: hoverShadow(colors.secondary.backgroundColor),
            },
            active: {
                ...buttonDefaultActive,
                backgroundColor: activeColor(colors.secondary.backgroundColor),
            },
            disabled: {
                ...buttonDefaultDisabled,
            },
            focus: buttonDefaultFocus,
        },
    };
}

export const withButtonTheme = <P extends object>(Component: React.ComponentType<P>): React.FC<P & WithThemeProps> => (props: WithThemeProps) => (
    <ThemeContext.Consumer>
        {theme => <Component {...props as P} theme={theme.button}/>}
    </ThemeContext.Consumer>
);
