import React, { memo } from "react";
import chroma from "chroma-js";
import { css, StyleSheet } from "aphrodite";
import { defaultTheme, Field, Button } from "..";
import { ContentBlock, convertFromRaw, convertToRaw, Editor, EditorState, RichUtils, DraftEditorCommand, getDefaultKeyBinding } from "draft-js";
import "draft-js/dist/Draft.css";

import { withForm, WithFormProps } from "../Form/@hoc/withForm";
import { IEditorOnChange } from "./@hoc/withEditor";
import { IEditorTheme } from "./Editor.types";
import { withEditorTheme, WithEditorThemeProps } from "./Editor.theme";
import { luminance } from "../@Theme/Colors";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};
const getBlockStyle = (block: ContentBlock) => {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return "";
  }
};

const BLOCK_TYPES = [
  {icon: ["fas", "h1"] as IconProp, style: 'header-one'},
  {icon: ["fas", "h2"] as IconProp, style: 'header-two'},
  {icon: ["fas", "h3"] as IconProp, style: 'header-three'},
  {icon: ["fas", "quote-right"] as IconProp, style: 'blockquote'},
  {icon: ["fas", "list-ul"] as IconProp, style: 'unordered-list-item'},
  {icon: ["fas", "list-ol"] as IconProp, style: 'ordered-list-item'},
  {icon: ["fas", "code"] as IconProp, style: 'code-block'},
];

export const INLINE_STYLES = [
  {icon: ["fas", "bold"] as IconProp, style: 'BOLD'},
  {icon: ["fas", "italic"] as IconProp, style: 'ITALIC'},
  {icon: ["fas", "underline"] as IconProp, style: 'UNDERLINE'},
  {icon: ["fas", "strikethrough"] as IconProp, style: 'STRIKETHROUGH'}
];

interface ToolbarButtonProps extends WithEditorThemeProps {
  style: string,
  icon: IconProp,
  onClick: (style: string) => void,
  active: boolean
}
const ToolbarButton = withEditorTheme((props: ToolbarButtonProps) => {
  
  const onClick = (e: React.FormEvent<HTMLButtonElement>) => props.onClick(props.style);
  
  const colors = props.colors || defaultTheme.colors;
  const theme = props.theme || defaultTheme.editor;
  
  const styles = StyleSheet.create({
    toolbarButton: {
      ...theme.toolbarButtons.default,
      minWidth: 40,
      justifyContent: "center",
      
      ":not(:last-child)": {
        marginRight: 0
      },
    },
    active: {
      ...theme.toolbarButtons.active
    }
  });
  
  return (
    <Button onClick={onClick} icon={props.icon} active={props.active} className={styles.toolbarButton} activeClass={styles.active}/>
  );
});

interface ToolbarProps extends WithEditorThemeProps {
  active: boolean
  editorState: EditorState
  onClick: (editorState: EditorState) => void
}

const Toolbar = withEditorTheme((props: ToolbarProps) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();
  const selection = props.editorState.getSelection();
  const blockType = props.editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  
  const onToggleInlineStyle = (style: string) => props.onClick(RichUtils.toggleInlineStyle(props.editorState, style));
  const onToggleBlockType = (style: string) => props.onClick(RichUtils.toggleBlockType(props.editorState, style));
  
  const colors = props.colors || defaultTheme.colors;
  const theme = props.theme || defaultTheme.editor;
  
  const styles = StyleSheet.create({
    toolBar: {
      ...theme.toolbar,
      position: "sticky",
      top: 0,
      zIndex: 5,
      padding: 0,
      marginBottom: "2px",
      flex: "1",
      display: "flex",
    }
  });
  
  return (
    <div className={css(styles.toolBar)}>
      {BLOCK_TYPES.map(type =>
        <ToolbarButton key={type.style} icon={type.icon} onClick={onToggleBlockType} style={type.style} active={props.active && type.style === blockType}/>
      )}
      {INLINE_STYLES.map(type =>
        <ToolbarButton key={type.style} icon={type.icon} onClick={onToggleInlineStyle} style={type.style} active={props.active && currentStyle.has(type.style)}/>
      )}
    </div>
  )
});


interface EditorProps extends WithFormProps {
  value: string
  name: string
  onChange: (value: IEditorOnChange) => void
  toolbar?: boolean | null
  allowEmpty?: boolean
  validator?: any
  readOnly?: boolean
  theme?: IEditorTheme
}

interface EditorDefaultProps extends EditorProps, WithEditorThemeProps {
  isValid?: boolean
  onFocus: () => void
}

type State = {
  timer: number
  isValid: boolean
  focused: boolean
  edited: boolean
  prevEdited: boolean
  editorState: EditorState
};

class _EditorDefault extends React.Component<EditorDefaultProps, Partial<State>> {
  
  state: State = {
    timer: 0,
    isValid: true,
    focused: false,
    edited: false,
    prevEdited: false,
    editorState: EditorState.createEmpty()
  };
  
  private timer: any;
  private editor: any;
  
  componentDidMount() {
    const editorState = this.createContent(this.props.value);
    const isValid = this.isValid(editorState);
    this.setState({isValid, editorState});
  }
  
  componentDidUpdate(prevProps: Readonly<EditorDefaultProps>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.value !== this.props.value || prevProps.hash !== this.props.hash) {
      const editorState = this.createContent(this.props.value);
      const isValid = this.isValid(editorState);
      this.setState({editorState, edited: false, prevEdited: false, isValid});
      this.stopTimer();
      
    } else {
      if (this.state.prevEdited !== prevState.edited)
        this.setState({prevEdited: prevState.edited});
    }
  }
  
  componentWillUnmount(): void {
    this.stopTimer();
  }
  
  startTimer = () => {
    this.timer = setInterval(() => this.tick(), 500);
  };
  restartTimer = () => {
    this.stopTimer();
    this.startTimer();
  };
  stopTimer = () => {
    this.setState({timer: 0});
    clearInterval(this.timer);
  };
  
  tick = () => {
    this.setState(state => ({timer: state.timer ? state.timer + 1 : 0}));
    this.propagateOnChange();
    this.stopTimer();
  };
  
  propagateOnChange = () => {
    if (this.changesExist() || this.state.edited !== this.state.prevEdited)
      this.props.onChange({data: {[this.props.name]: this.valueToString()}, error: false});
  };
  
  createContent = (value: string): EditorState => value > ""
    ? EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
    : EditorState.createEmpty();
  
  _onFocus = () => this.setState({focused: true});
  onFocus = () => this.editor && this.editor.focus();
  setEditor = (editor: Editor) => this.editor = editor;
  
  onChange = (editorState: EditorState, onUpdate?: () => void) => {
    const isValid = this.isValid(editorState);
    
    this.restartTimer();
    this.setState({editorState, isValid, edited: this.changesExist(editorState)} as Partial<State>, onUpdate);
  };
  
  isValid = (editorState: EditorState) => {
    const {validator, allowEmpty} = this.props;
    const value = this.valueToString(editorState);
    
    return !!(validator ? validator(value) : value > "" || allowEmpty);
  };
  
  changesExist = (editorState?: EditorState) => this.props.value !== this.valueToString(editorState);
  
  valueToString = (editorState?: EditorState) => {
    const es = editorState
      ? editorState.getCurrentContent()
      : this.state.editorState.getCurrentContent();
    return es.hasText()
      ? JSON.stringify(convertToRaw(es))
      : "";
  };
  
  onBlur = () => {
    this.setState({focused: false});
  };
  
  handleKeyCommand = (command: DraftEditorCommand, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };
  
  mapKeyToEditorCommand = (e: React.KeyboardEvent): DraftEditorCommand | string | null => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        this.state.editorState,
        4 /* maxDepth */,
      );
      if (newEditorState !== this.state.editorState) {
        this.onChange(newEditorState);
      }
      return "";
    }
    return getDefaultKeyBinding(e);
  };
  
  onTollbarClick = (editorState: EditorState) => {
    this.onChange(editorState, this.onFocus);
  };
  
  render() {
    const {editorState, edited, focused, isValid} = this.state;
    const {props} = this;
    
    const colors = props.colors || defaultTheme.colors;
    const theme = props.theme || defaultTheme.editor;
    
    const styles = StyleSheet.create({
      editor: {
        display: "flex",
        flex: "1",
        flexDirection: "column"
      },
      field: {
        width: "100%",
        display: "flex"
      },
      wrapper: {
        ...theme.default,
        borderColor: isValid ? edited ? colors.primary.backgroundColor : theme.colors.borderColor : theme.colors.errorColor,
        
        ":active": {
          ...theme.focus,
        },
      },
      notFocused: {
        backgroundColor: theme.colors.backgroundColor,
        
        ":hover": {
          ...theme.hover,
          borderColor: isValid ? edited ? colors.primary.backgroundColor : luminance(theme.colors.borderColor, .5, .3, .3) : theme.colors.errorColor,
        },
      },
      focused: {
        ...theme.focus,
        borderColor: isValid ? edited ? colors.primary.backgroundColor : luminance(theme.colors.borderColor, .5, .3, .3) : theme.colors.errorColor,
      }
    });
    
    return (
      <div className={css(styles.editor)} onBlur={this.onBlur}>
        
        {(props.toolbar === undefined || props.toolbar === null || props.toolbar) &&
        <Toolbar editorState={editorState} onClick={this.onTollbarClick} active={focused}/>}
        
        <div className={css(styles.field)}>
          <section className={css([styles.wrapper, focused ? styles.focused : styles.notFocused])} onClick={this.onFocus}>
            <Editor
              ref={this.setEditor}
              editorState={editorState}
              onFocus={this._onFocus}

              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              onChange={this.onChange}
              keyBindingFn={this.mapKeyToEditorCommand}
              handleKeyCommand={this.handleKeyCommand}
            />
          </section>
        </div>
      
      </div>
    )
  }
}

const EditorWithTheme = withEditorTheme(_EditorDefault);
const EditorWithForm = withForm(EditorWithTheme);

const EditorRTF = (props: EditorProps) => {
  return (
    <EditorWithForm onFocus={focus} {...props} />
  );
};

const FieldEditorRTF = memo((props: EditorProps) => {
  
  return (
    <Field>
      <EditorRTF {...props} />
    </Field>
  );
});

export { FieldEditorRTF };