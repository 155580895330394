import React, {ReactNode} from "react";

export const FormContext = React.createContext("form");

type Props = {
  hash: string,
  children: ReactNode | ReactNode[]
};

export const FormProvider = (props: Props) => (
  <FormContext.Provider value={props.hash}>
    {props.children}
  </FormContext.Provider>
);

export default FormProvider;