import chroma from "chroma-js";
import {IColors} from "../@Theme/Colors";
import {IVariables} from "../@Theme/Variables";
import {ILoaderTheme} from "./Loader.types";

export function LoaderThemeBuilder(variables: IVariables, colors: IColors): ILoaderTheme {
    return ({
      color: chroma(colors.main.backgroundColor).luminance() <= .5
          ? chroma("white").alpha(0.5).hex()
          : chroma("black").alpha(0.5).hex(),
      width: variables.loaderWidth
    });
}
