import { ILoaderVariables } from "../Loader/Loader.types";

export interface IVariables extends ILoaderVariables {
    fontSize: string,
    borderRadius: string,
    borderRadiusSmall: string,
}

export const variables: IVariables = {
    fontSize: "14",
    loaderWidth: 4,
    borderRadius: "0",
    borderRadiusSmall: "0",
};