import React from 'react';
import ReactDOM from 'react-dom';
import './example/styles/index.css';
import Router from './example/Router';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Router/>, document.getElementById('root') as HTMLElement);

export interface WebpackNodeModule extends NodeModule { hot: { accept: (name: string, handler: () => void) => void }; }

if ((module as WebpackNodeModule).hot)
  (module as WebpackNodeModule).hot.accept("./example/Router", () => {
    const UpdatedRouter = require("./example/Router").default;
    ReactDOM.render(<UpdatedRouter/>, document.getElementById('root') as HTMLElement);
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
